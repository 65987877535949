const routePaths = {
  MAIN: '/',
  SERVER_UNAVAILABLE: '/server-unavailable',

  // Onboard routes
  SIGN_IN: '/sign-in',
  SIGN_IN_CONFIRMATION_REDIRECT: '/sign-in/confirmation/:service',
  SIGN_IN_CONFIRMATION_SERVICE: '/sign-in/confirmation-service/:service',
  SIGN_OUT: '/sign-out',

  REGISTER: '/register',
  REGISTER_CONFIRMATION: '/register/confirmation/:token',

  RECOVER_ACCOUNT: '/recover-account',
  RECOVER_PASSWORD: '/recover-password',
  RECOVER_PASSWORD_CONFIRMATION: '/recover-password/confirmation/:token',

  EMAIL_CONFIRMATION: '/email-confirmation',

  INVITE_CONFIRMATION: '/invite-confirmation/:token',
  ONBOARD: '/onboard',
  ONBOARD_FINISH: '/onboard-finish',

  // System routes
  DASHBOARD: '/dashboard',
  SETTINGS: '/settings',

  USERS: '/users',
  USER: '/user/:slug',
  PROFILE: '/profile',
  PROFILE_EDIT_IMAGE: '/profile/edit-image',

  BILLING: '/billing',

  WHITELABELS: '/whitelabels',
  WHITELABEL: '/whitelabel/:slug',

  ACCOUNTS: '/accounts',
  ACCOUNT: '/account/:slug',

  COMPANIES: '/companies',
  COMPANY: '/company/:slug',

  SUBSIDIARIES: '/subsidiaries',
  SUBSIDIARY: '/subsidiary/:slug',

  ACQUIRER: '/acquirer/:slug',

  CONFIGURATION: '/configuration/:slug',

  DEVICE: '/device/:slug',
  DEPARTMENT: '/department/:slug',

  INVOICES: '/invoices',
  INVOICE: '/invoice/:slug/:tpEmis',

  FINANCIAL: '/financial',
  TRANSACTIONS: '/transactions',
  TRANSACTION: '/transaction/:slug',
  TRANSACTION_DUPLICATE_DETAIL: '/duplicate/:nParc/:nDup',

  RULES_ENGINES: '/rules-engines',
  RULES_ENGINE: '/rules-engine/:accountId/:slug',

  SERIE: '/serie/:slug',

  TERMINAL: '/terminal/:slug',

  PARTICIPANT: '/participant/:slug',

  TAX_EFD_ICMS_IPI: '/tax-efd-icms-ipi/:slug',
  TAXS_EFD_ICMS_IPI: '/taxs-efd-icms-ipi',
  ASCERTAINMENT_TAX: '/ascertainment-tax/:type',

  TAXS_EFD_CONTRIBUTIONS: '/efd-contributions',
  TAX_EFD_CONTRIBUTIONS: '/efd-contributions/:slug',
  EFD_CONTRIBUTIONS_SIGNATORY: '/efd-contributions-signatory/:slug',
  EFD_CONTRIBUTIONS_ACCOUNTS_PLAN: '/efd-contributions-accounts-plan/:slug',
  EFD_CONTRIBUTIONS_SUBSIDIARIES: '/efd-contributions-subsidiaries/:slug',
  EFD_CONTRIBUTIONS_REGIME: '/efd-contributions-regime/:slug',
  EFD_CONTRIBUTIONS_PARTICIPANT: '/efd-contributions-participant/:slug',
  EFD_CONTRIBUTIONS_ITEM: '/efd-contributions-item/:slug',
  EFD_CONTRIBUTIONS_OTHER_DOCUMENTS: '/other-documents',
  EFD_CONTRIBUTIONS_CONTRIBUTION_AND_CREDIT: '/contribution-and-credit/:slug',
  EFD_CONTRIBUTIONS_DEPRECIATION_CHARGE: '/depreciation-charge/:slug',
  EFD_CONTRIBUTIONS_ACQUISITION_VALUE: '/acquisition-value/:slug',
  EFD_CONTRIBUTIONS_PRESUMED_CREDIT: '/presumed-value/:slug',
  EFD_CONTRIBUTIONS_SOLD_REAL_ESTATE_UNIT: '/sold-real-estate-unit/:slug',
  SOLD_REAL_ESTATE_UNIT_INCURRED_COST: '/incurred-cost/:slug',
  SOLD_REAL_ESTATE_UNIT_BUDGETED_COST: '/budgeted-cost-teste/:slug',
  EFD_CONTRIBUTIONS_CASH_REGIME: '/cash-regime/:slug',
  EFD_CONTRIBUTIONS_OPERATION_WITH_INCIDENCE: '/operation-with-incidence/:slug',
  EFD_CONTRIBUTIONS_REVENUE_COMPOSITION: '/revenue-compositions/:slug',
  EFD_CONTRIBUTIONS_WITHHOLDING_CONTRIBUTION: '/withholding-contribution/:slug',
  EFD_CONTRIBUTIONS_VARIOUS_DEDUCTION: '/various-deduction/:slug',
  EFD_CONTRIBUTIONS_RESULTING_CREDIT: '/resulting-credit/:slug',
  EFD_CONTRIBUTIONS_ASCERTAINMENT: '/ascertainment/:slug/:trib',
  PIS_PASEP_PERIOD_CREDIT: '/pis-pasep/period-credit/:slug',
  PIS_PASEP_CALCULATION_BASE: '/pis-pasep/calculation-base/:slug',
  PIS_PASEP_CREDIT_ADJUSTMENT: '/pis-pasep/credit-adjustment/:slug',
  PIS_PASEP_DETAILED_CREDIT_ADJUSTMENT: '/pis-pasep/detailed-credit-adjustment/:slug',
  PIS_PASEP_CONTRIBUTION_PERIOD: '/pis-pasep/contribution-period/:slug',
  PIS_PASEP_REVENUE_CODE: '/pis-pasep/revenue-code/:slug',
  PIS_PASEP_CONTRIBUTION_CODE: '/pis-pasep/contribution-code/:slug',
  PIS_PASEP_CONTRIBUTION_COOPERATIVE_SOCIETY: '/pis-pasep/coperative-society/:slug',
  PIS_PASEP_CALCULATION_BASE_ADJUSTMENT: '/pis-pasep/calculation-base-adjustment/:slug',
  PIS_PASEP_CONTRIBUTION_ADJUSTMENT: '/pis-pasep/contribution-adjustiment/:slug',
  PIS_PASEP_ADDITIONAL_INFORMATION: '/pis-pasep/additional-information/:slug',
  PIS_PASEP_FIT_DETAILED: '/pis-pasep/fit-detailed/:slug',
  PIS_PASEP_CONTRIBUTIONS_FROM_PREVIOUS_PERIOD: '/pis-pasep/contributions-from-previous-period/:slug',
  PIS_PASEP_PAYROLL: '/pis-pasep/payroll/:slug',
  PIS_PASEP_EXEMPT_RECIPE: '/pis-pasep/exempt-recipe/:slug',
  COFINS_PERIOD_CREDIT: '/cofins/period-credit/:slug',
  COFINS_CALCULATION_BASE: '/cofins/calculation-base/:slug',
  COFINS_CREDIT_ADJUSTMENT: '/cofins/credit-adjustment/:slug',
  COFINS_DETAILED_CREDIT_ADJUSTMENT: '/cofins/detailed-credit-adjustment/:slug',
  COFINS_CONTRIBUTION_PERIOD: '/cofins/contribution-period/:slug',
  COFINS_REVENUE_CODE: '/cofins/revenue-code/:slug',
  COFINS_CONTRIBUTION_CODE: '/cofins/contribution-code/:slug',
  COFINS_COOPERATIVE_SOCIETY: '/cofins/coperative-society/:slug',
  COFINS_CALCULATION_BASE_ADJUSTMENT: '/cofins/calculation-base-adjustment/:slug',
  COFINS_CONTRIBUTION_ADJUSTMENT: '/cofins/contribution-adjustiment/:slug',
  COFINS_ADDITIONAL_INFORMATION: '/cofins/additional-information/:slug',
  COFINS_FIT_DETAILED: '/cofins/fit-detailed/:slug',
  COFINS_AMOUNTS_PAYABLE_IN_THE_PERIOD: '/cofins/amounts-payable-in-the-period/:slug',
  COFINS_EXEMPT_RECIPE: '/cofins/exempt-recipe/:slug',
  CPRB_REVENUE_BOOKKEEPING: '/cprb/revenue-bookkeeping/:slug',
  CPRB_ABOUT_THE_VALUE: '/cprb/about-the-value/:slug',
  CPRB_BOOKKEEPING_SUPPLEMENT: '/cprb/bookkeeping-supplement/:slug',
  CPRB_CONSOLIDATION: '/cprb/consolidation/:slug',
  CPRB_CONTRIBUTION_ADJUSTMENT: '/cprb/contribution-adjustment/:slug',
  EFD_CONTRIBUTIONS_BOOKKEEPING_COMPLEMENTS: '/bookkeeping-complements',
  BOOKKEEPING_COMPLEMENTS_LAWSUIT: '/lawsuit/:slug',
  LAWSUIT_REQUIRED_CONTRIBUTION: '/required-contribution/:slug',
  BOOKKEEPING_COMPLEMENTS_CALCULATION_VALUE: '/calculation-value/:slug',
  BOOKKEEPING_COMPLEMENTS_TAX_CREDIT_PIS_PASEP: '/tax-credit-pis-pasep/:slug',
  BOOKKEEPING_COMPLEMENTS_AMOUNT_WITHHELD_AT_SOURCE_PIS_PASEP: '/amount-withheld-at-source-pis-pasep/:slug',
  BOOKKEEPING_COMPLEMENTS_TAX_CREDIT_COFINS: '/tax-credit-cofins/:slug',
  BOOKKEEPING_COMPLEMENTS_AMOUNT_WITHHELD_AT_SOURCE_COFINS: '/amount-withheld-at-source-cofins/:slug',
  BOOKKEEPING_COMPLEMENTS_REAL_ESTATE_DEVELOPMENT: '/real-estate-development/:slug',
  BOOKKEEPING_COMPLEMENTS_CASH_OR_ACCRUAL_REGIME: '/cash-or-accrual-regime/:slug',

  ASCERTAINMENTS: '/ascertainments',
  ASCERTAINMENT_SUBSIDIARY: '/ascertainment-subsidiary',
  ASCERTAINMENT_TAXES: '/ascertainment-taxes',
  TAX: '/tax/:slug/:type',
  CALCULATION_DETAIL: '/calculation',

  ACCOUNTING_ECD: '/accounting-ecd/:slug',
  ACCOUNTINGS_ECD: '/accountings-ecd',
  ECD_OTHERS_INFORMATIONS: '/ecd-others-informations/:slug',
  ECD_SIGNATORY: '/ecd-sigantory/:slug',
  ECD_ECONOMIC_CONGLOMERATE: '/ecd-economic-conglomerate/:slug',
  ECD_PARTICIPANT: '/ecd-participant/:slug',
  ECD_ACCOUNTS_PLAN: '/ecd-accounts-plan/:slug',
  ECD_CONSOLIDATED_ACCOUNTS_PLAN: '/ecd-consolidated-accounts-plan/:slug',
  ECD_CONSOLIDATED_ACCOUNT_BALANCES: '/ecd-consolidated-account-balances/:slug',
  ECD_COMPANIES_HOLDING_THE_PARCELS: '/ecd-companies-holding-the-parcels/:slug',
  ECD_CONSOLIDATED_COMPANIES_RELATIONS: '/ecd-consolidated-companies-relations/:slug',
  ECD_CORPORATE_EVENTS_RELATIONS: '/ecd-corporate-events-relations/:slug',
  ECD_DLPA_DMPL: '/ecd-dlpa-dmpl/:slug/:type',
  ECD_DLPA_DMPL_STATEMENT: '/ecd-dlpa-dmpl-statement/:slug',
  ECD_BALANCE_SHEET: '/ecd-balance-sheet/:slug',
  ECD_BALANCE_SHEET_STATEMENT: '/ecd-balance-sheet-statement/:slug',
  ECD_DRE: '/ecd-dre/:slug',
  ECD_DRE_STATEMENT: '/ecd-dre-statement/:slug',

  ACCOUNTING_ECF: '/accounting-ecf/:slug',
  ACCOUNTINGS_ECF: '/accountings-ecf',
  IDENTIFICATION_OF_MEMBERS_OR_HOLDERS: '/identification-of-members-or-holders/:slug',
  ECF_ASCERTAINMENT_DETAIL: '/ascertainment/:slug/:type',
  ACCOUNT_IDENTIFICATION: '/account-identification/:slug',
  LAUNCHES_WITHOUT_REFLECTIONS: '/launches-without-reflections/:slug',

  ACCOUNT_PLAN: '/accounting-chart/:slug',
  ACCOUNT_PLAN_LIST: '/accounting-charts',
  ACCOUNT_PLAN_ACCOUNT_DETAILED: '/accounting-code/:slug',

  DIARIES: '/diaries',
  BALANCES: '/patrimonial-trial-balances',
  BALANCE: '/patrimonial-trial-balance/:slug',
  ACCOUNT_BALANCE: '/patrimonial-trial-balance-account/:slug',

  INCOME_STATEMENTS: '/dre-trial-balances',
  INCOME_STATEMENT: '/dre-trial-balance/:slug',
  ACCOUNT_INCOME_STATEMENTS: '/dre-trial-balance-account/:slug',

  COMPREHENSIVE_RESULTS: '/dra',
  COMPREHENSIVE_RESULT: '/dra/:slug',
  ACCOUNT_COMPREHENSIVE_RESULTS: '/dra-account/:slug',

  ADDED_VALUES: '/dva',
  ADDED_VALUE: '/dva/:slug',
  ACCOUNT_ADDED_VALUES: '/dva-account/:slug',

  CASH_FLOWS: '/dfc',
  CASH_FLOW: '/dfc/:slug',
  ACCOUNT_CASH_FLOWS: '/dfc-account/:slug',

  BALANCES_SHEETS: '/trial-balances',
  BALANCE_SHEETS: '/trial-balance/:slug',
  BALANCE_SHEET: '/trial-balance-account/:slug',

  LEDGERS: '/ledgers',
  LEDGER: '/ledger/:slug',
  LEDGER_ACCOUNTS: '/ledger-accounts',
  ACCOUNTING_ENTRIES_LEDGER: '/ledger-account/:slug',

  HERITAGE_MUTATIONS: '/heritage-mutations',
  HERITAGE_MUTATION: '/heritage-mutation/:slug',
  ACCOUNT_HERITAGE_MUTATIONS: '/heritage-trial-balance-account/:slug',

  ACCOUNTING_ENTRIES_DIARY: '/entries/:slug',
  ACCOUNTING_ENTRIES_BALANCE: '/entries-balance/:slug',
  DIARY: '/diary/:slug',

  ITEM: '/entries-items/:slug',

  CATEGORY: '/category/:slug',
  RESULT_CENTER_ACCOUNT_DETAILED: '/result-center-account/:slug',

  PRODUCT: '/product/:slug',

  SIGNATORY: '/signatory/:slug',

  WEBHOOK: '/webhook/:slug',
  WEBHOOKS: '/webhooks',
  WEBHOOK_DETAILED: '/deliveries/:slug',

  NOT_FOUND: '*',

  TAB_SUB_ROUTE: '/tab/:tab',
  ACTION_SUB_ROUTE: '/:action/:actionId?',

  CERTIFICATE: '/certificate',
  CERTIFICATES: '/certificates',

  MAILBOX: '/mailbox',
  MAILBOXS: '/mailboxs',

  TAX_MENU: '/tax-menu',
}

export default routePaths
