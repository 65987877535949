import lazyWithRetry from 'commons/react/lazyWithRetry'

import routePaths from './routes-paths'

const Subsidiaries: any = lazyWithRetry((): any => import('pages/subsidiaries/Subsidiaries'))
const AccountPlan: any = lazyWithRetry((): any => import('pages/account-plans/AccountPlan'))
const AccountPlanAccountsContent: any = lazyWithRetry((): any => import('pages/accounting-codes/AccountingCode'))
const Signatory: any = lazyWithRetry((): any => import('pages/signatories/Signatory'))
const Invoices: any = lazyWithRetry((): any => import('pages/invoices/Invoices'))
const Invoice: any = lazyWithRetry((): any => import('pages/invoices/Invoice'))
const Financial: any = lazyWithRetry((): any => import('pages/financial/Financial'))
const Transactions: any = lazyWithRetry((): any => import('pages/financial/Transactions'))
const Transaction: any = lazyWithRetry((): any => import('pages/financial/Transaction'))
const Duplicate: any = lazyWithRetry((): any => import('pages/financial/content/details/duplicate/Duplicate'))
const Dashboard: any = lazyWithRetry((): any => import('pages/dashboard/Dashboard'))
const Acquirer: any = lazyWithRetry((): any => import('pages/acquirers/Acquirer'))
const Device: any = lazyWithRetry((): any => import('pages/devices/Device'))
const Configurations: any = lazyWithRetry((): any => import('pages/configurations/Configuration'))
const Department: any = lazyWithRetry((): any => import('pages/departments/Department'))
const Settings: any = lazyWithRetry((): any => import('pages/settings/Settings'))
const Users: any = lazyWithRetry((): any => import('pages/users/Users'))
const User: any = lazyWithRetry((): any => import('pages/users/User'))
const Billing: any = lazyWithRetry((): any => import('pages/billing/Billing'))
const Whitelabels: any = lazyWithRetry((): any => import('pages/whitelabels/Whitelabels'))
const Whitelabel: any = lazyWithRetry((): any => import('pages/whitelabels/Whitelabel'))
const Accounts: any = lazyWithRetry((): any => import('pages/accounts/Accounts'))
const Account: any = lazyWithRetry((): any => import('pages/accounts/Account'))
const Diaries: any = lazyWithRetry((): any => import('pages/diary/Diaries'))
const Diary: any = lazyWithRetry((): any => import('pages/diary/Diary'))
const Companies: any = lazyWithRetry((): any => import('pages/companies/Companies'))
const Company: any = lazyWithRetry((): any => import('pages/companies/Company'))
const Subsidiary: any = lazyWithRetry((): any => import('pages/subsidiaries/Subsidiary'))
const Category: any = lazyWithRetry((): any => import('pages/category/Category'))
const Product: any = lazyWithRetry((): any => import('pages/products/Product'))
const RulesEngines: any = lazyWithRetry((): any => import('pages/rules-engines/RulesEngines'))
const RulesEngine: any = lazyWithRetry((): any => import('pages/rules-engines/RulesEngine'))
const Serie: any = lazyWithRetry((): any => import('pages/series/Serie'))
const Participant: any = lazyWithRetry((): any => import('pages/participants/Participant'))
const Terminal: any = lazyWithRetry((): any => import('pages/terminals/Terminal'))
const TaxsEfdIcmsIpi: any = lazyWithRetry((): any => import('pages/tax-efd-icms-ipi/TaxsEfdIcmsIpi'))
const AscertainmentTax: any = lazyWithRetry((): any => import('pages/tax-efd-icms-ipi/content/ascertainment/AscertainmentTax'))
const TaxEfdIcmsIpi: any = lazyWithRetry((): any => import('pages/tax-efd-icms-ipi/TaxEfdIcmsIpi'))
const TaxEFDContributions: any = lazyWithRetry((): any => import('pages/tax-efd-contributions/TaxEFDContributions'))
const TaxsEFDContributions: any = lazyWithRetry((): any => import('pages/tax-efd-contributions/TaxsEFDContributions'))
const EFDContributionsSignatory: any = lazyWithRetry((): any => import('pages/tax-efd-contributions/content/details/signatories/EFDContributionsSignatory'))
const EFDContributionsAccountsPlan: any = lazyWithRetry((): any => import('pages/tax-efd-contributions/content/details/accounts-plan/EFDContributionsAccountsPlan'))
const EFDContributionsSubsidiary: any = lazyWithRetry((): any => import('pages/tax-efd-contributions/content/details/subsidiaries/EFDContributionsSubsidiary'))
const EFDContributionsRegime: any = lazyWithRetry((): any => import('pages/tax-efd-contributions/content/details/subsidiaries/regime/EFDContributionsRegime'))
const EFDContributionsParticipant: any = lazyWithRetry((): any => import('pages/tax-efd-contributions/content/details/subsidiaries/participant/EFDContributionsParticipant'))
const EFDContributionsItem: any = lazyWithRetry((): any => import('pages/tax-efd-contributions/content/details/subsidiaries/items/EFDContributionsItem'))
const EFDContributionsOtherDocuments: any = lazyWithRetry((): any => import('pages/tax-efd-contributions/content/details/subsidiaries/EFDContributionsOtherDocuments'))
const EFDContributionsContributionAndCredit: any = lazyWithRetry((): any => import('pages/tax-efd-contributions/content/details/subsidiaries/other-documents/contribution-and-credits/EFDContributionsContributionAndCredit'))
const EFDContributionsDepreciationCharge: any = lazyWithRetry((): any => import('pages/tax-efd-contributions/content/details/subsidiaries/other-documents/depreciation-charges/EFDContributionsDepreciationCharge'))
const EFDContributionsAcquisitionValue: any = lazyWithRetry((): any => import('pages/tax-efd-contributions/content/details/subsidiaries/other-documents/acquisition-values/EFDContributionsAcquisitionValue'))
const EFDContributionsPresumedCredit: any = lazyWithRetry((): any => import('pages/tax-efd-contributions/content/details/subsidiaries/other-documents/presumed-credits/EFDContributionsPresumedCredit'))
const EFDContributionsSoldRealEstateUnit: any = lazyWithRetry((): any => import('pages/tax-efd-contributions/content/details/subsidiaries/other-documents/sold-real-estate-units/EFDContributionsSoldRealEstateUnit'))
const SoldRealEstateUnitIncurredCost: any = lazyWithRetry((): any => import('pages/tax-efd-contributions/content/details/subsidiaries/other-documents/sold-real-estate-units/incurred-cost/SoldRealEstateUnitIncurredCost'))
const SoldRealEstateUnitBudgetedCost: any = lazyWithRetry((): any => import('pages/tax-efd-contributions/content/details/subsidiaries/other-documents/sold-real-estate-units/budgeted-cost/SoldRealEstateUnitBudgetedCost'))
const EFDContributionsCashRegime: any = lazyWithRetry((): any => import('pages/tax-efd-contributions/content/details/subsidiaries/other-documents/cash-regimes/EFDContributionsCashRegime'))
const EFDContributionsOperationWithIncidence: any = lazyWithRetry((): any => import('pages/tax-efd-contributions/content/details/subsidiaries/other-documents/operations-with-incidence/EFDContributionsOperationWithIncidence'))
const EFDContributionsRevenueComposition: any = lazyWithRetry((): any => import('pages/tax-efd-contributions/content/details/subsidiaries/other-documents/revenue-compositions/EFDContributionsRevenueComposition'))
const EFDContributionsWithholdingContribution: any = lazyWithRetry((): any => import('pages/tax-efd-contributions/content/details/subsidiaries/other-documents/withholding-contributions/EFDContributionsWithholdingContribution'))
const EFDContributionsVariousDeduction: any = lazyWithRetry((): any => import('pages/tax-efd-contributions/content/details/subsidiaries/other-documents/various-deductions/EFDContributionsVariousDeduction'))
const EFDContributionsResultingCredit: any = lazyWithRetry((): any => import('pages/tax-efd-contributions/content/details/subsidiaries/other-documents/resulting-credits/EFDContributionsResultingCredit'))
const EFDContributionsAscertainment: any = lazyWithRetry((): any => import('pages/tax-efd-contributions/content/details/ascertainments/EFDContributionsAscertainment'))
const PisPasepPeriodCredit: any = lazyWithRetry((): any => import('pages/tax-efd-contributions/content/details/ascertainments/pis-pasep/period-credits/PisPasepPeriodCredit'))
const PisPasepCalculationBase: any = lazyWithRetry((): any => import('pages/tax-efd-contributions/content/details/ascertainments/pis-pasep/period-credits/content/calculation-base/PisPasepCalculationBase'))
const PisPasepCreditAdjustment: any = lazyWithRetry((): any => import('pages/tax-efd-contributions/content/details/ascertainments/pis-pasep/period-credits/content/credit-adjustment/PisPasepCreditAdjustment'))
const PisPasepDetailedCreditAdjustment: any = lazyWithRetry((): any => import('pages/tax-efd-contributions/content/details/ascertainments/pis-pasep/period-credits/content/credit-adjustment/credit-adjustment/PisPasepDetailedCreditAdjustment'))
const PisPasepContributionPeriod: any = lazyWithRetry((): any => import('pages/tax-efd-contributions/content/details/ascertainments/pis-pasep/contributions-period/PisPasepContributionPeriod'))
const PisPasepContributionsFromPreviousPeriod: any = lazyWithRetry((): any => import('pages/tax-efd-contributions/content/details/ascertainments/pis-pasep/contributions-from-previous-periods/PisPasepContributionsFromPreviousPeriod'))
const PisPasepPayroll: any = lazyWithRetry((): any => import('pages/tax-efd-contributions/content/details/ascertainments/pis-pasep/payrolls/PisPasepPayroll'))
const PisPasepExemptRecipe: any = lazyWithRetry((): any => import('pages/tax-efd-contributions/content/details/ascertainments/pis-pasep/exempt-recipes/PisPasepExemptRecipe'))
const PisPasepRevenueCode: any = lazyWithRetry((): any => import('pages/tax-efd-contributions/content/details/ascertainments/pis-pasep/contributions-period/content/revenue-codes/PisPasepRevenueCode'))
const PisPasepContributionCode: any = lazyWithRetry((): any => import('pages/tax-efd-contributions/content/details/ascertainments/pis-pasep/contributions-period/content/contribution-codes/PisPasepContributionCode'))
const PisPasepCooperativeSociety: any = lazyWithRetry((): any => import('pages/tax-efd-contributions/content/details/ascertainments/pis-pasep/contributions-period/content/contribution-codes/content/cooperative-societies/PisPasepCooperativeSociety'))
const PisPasepCalculationBaseAdjustment: any = lazyWithRetry((): any => import('pages/tax-efd-contributions/content/details/ascertainments/pis-pasep/contributions-period/content/contribution-codes/content/calculation-base-adjustments/PisPasepCalculationBaseAdjustment'))
const PisPasepContributionAdjustment: any = lazyWithRetry((): any => import('pages/tax-efd-contributions/content/details/ascertainments/pis-pasep/contributions-period/content/contribution-codes/content/contribution-adjustments/PisPasepContributionAdjustment'))
const PisPasepAdditionalInformation: any = lazyWithRetry((): any => import('pages/tax-efd-contributions/content/details/ascertainments/pis-pasep/contributions-period/content/contribution-codes/content/additional-informations/PisPasepAdditionalInformation'))
const PisPasepFitDetailed: any = lazyWithRetry((): any => import('pages/tax-efd-contributions/content/details/ascertainments/pis-pasep/contributions-period/content/contribution-codes/content/contribution-adjustments/fit-detailed/PisPasepFitDetailed'))
const CofinsPeriodCredit: any = lazyWithRetry((): any => import('pages/tax-efd-contributions/content/details/ascertainments/cofins/period-credits/CofinsPeriodCredit'))
const CofinsCalculationBase: any = lazyWithRetry((): any => import('pages/tax-efd-contributions/content/details/ascertainments/cofins/period-credits/content/calculation-base/CofinsCalculationBase'))
const CofinsCreditAdjustment: any = lazyWithRetry((): any => import('pages/tax-efd-contributions/content/details/ascertainments/cofins/period-credits/content/credit-adjustment/CofinsCreditAdjustment'))
const CofinsDetailedCreditAdjustment: any = lazyWithRetry((): any => import('pages/tax-efd-contributions/content/details/ascertainments/cofins/period-credits/content/credit-adjustment/detailed-credit-adjustment/CofinsDetailedCreditAdjustment'))
const CofinsContributionPeriod: any = lazyWithRetry((): any => import('pages/tax-efd-contributions/content/details/ascertainments/cofins/contributions-period/CofinsContributionPeriod'))
const CofinsContributionCode: any = lazyWithRetry((): any => import('pages/tax-efd-contributions/content/details/ascertainments/cofins/contributions-period/content/contribution-codes/CofinsContributionCode'))
const CofinsRevenueCode: any = lazyWithRetry((): any => import('pages/tax-efd-contributions/content/details/ascertainments/cofins/contributions-period/content/revenue-codes/CofinsRevenueCode'))
const CofinsAdditionalInformation: any = lazyWithRetry((): any => import('pages/tax-efd-contributions/content/details/ascertainments/cofins/contributions-period/content/contribution-codes/content/additional-informations/CofinsAdditionalInformation'))
const CofinsCalculationBaseAdjustment: any = lazyWithRetry((): any => import('pages/tax-efd-contributions/content/details/ascertainments/cofins/contributions-period/content/contribution-codes/content/calculation-base-adjustments/CofinsCalculationBaseAdjustment'))
const CofinsContributionAdjustment: any = lazyWithRetry((): any => import('pages/tax-efd-contributions/content/details/ascertainments/cofins/contributions-period/content/contribution-codes/content/contribution-adjustments/CofinsContributionAdjustment'))
const CofinsFitDetailed: any = lazyWithRetry((): any => import('pages/tax-efd-contributions/content/details/ascertainments/cofins/contributions-period/content/contribution-codes/content/contribution-adjustments/fit-detailed/CofinsFitDetailed'))
const CofinsCooperativeSociety: any = lazyWithRetry((): any => import('pages/tax-efd-contributions/content/details/ascertainments/cofins/contributions-period/content/contribution-codes/content/cooperative-societies/CofinsCooperativeSociety'))
const CofinsAmountsPayableInThePeriod: any = lazyWithRetry((): any => import('pages/tax-efd-contributions/content/details/ascertainments/cofins/amounts-payable-in-the-period/CofinsAmountsPayableInThePeriod'))
const CofinsExemptRecipe: any = lazyWithRetry((): any => import('pages/tax-efd-contributions/content/details/ascertainments/cofins/exempt-recipes/CofinsExemptRecipe'))
const CprbRevenueBookkeeping: any = lazyWithRetry((): any => import('pages/tax-efd-contributions/content/details/ascertainments/cprb/CprbRevenueBookkeeping'))
const CprbAboutTheValue: any = lazyWithRetry((): any => import('pages/tax-efd-contributions/content/details/ascertainments/cprb/revenue-bookkeeping/about-the-values/CprbAboutTheValue'))
const BookkeepingSupplement: any = lazyWithRetry((): any => import('pages/tax-efd-contributions/content/details/ascertainments/cprb/revenue-bookkeeping/about-the-values/bookkeeping-supplement/BookkeepingSupplement'))
const Consolidation: any = lazyWithRetry((): any => import('pages/tax-efd-contributions/content/details/ascertainments/cprb/revenue-bookkeeping/consolidations/Consolidation'))
const ContributionAdjustment: any = lazyWithRetry((): any => import('pages/tax-efd-contributions/content/details/ascertainments/cprb/revenue-bookkeeping/consolidations/contribution-adjustment/ContributionAdjustment'))
const EFDContributionsBookkeepingComplements: any = lazyWithRetry((): any => import('pages/tax-efd-contributions/content/details/EFDContributionsBookkeepingComplements'))
const EFDContributionsLawsuit: any = lazyWithRetry((): any => import('pages/tax-efd-contributions/content/details/bookkeeping-complements/lawsuit/EFDContributionsLawsuit'))
const LawsuitRequiredContribution: any = lazyWithRetry((): any => import('pages/tax-efd-contributions/content/details/bookkeeping-complements/lawsuit/required-contributions/LawsuitRequiredContribution'))
const EFDContributionsExtraCalculationValue: any = lazyWithRetry((): any => import('pages/tax-efd-contributions/content/details/bookkeeping-complements/extra-calculation-values/EFDContributionsExtraCalculationValue'))
const EFDContributionsTaxCreditPisPasep: any = lazyWithRetry((): any => import('pages/tax-efd-contributions/content/details/bookkeeping-complements/tax-credit-pis-pasep/EFDContributionsTaxCreditPisPasep'))
const EFDContributionsAmountWithheldAtSourcePisPasep: any = lazyWithRetry((): any => import('pages/tax-efd-contributions/content/details/bookkeeping-complements/amounts-withheld-at-source-pis-pasep/EFDContributionsAmountWithheldAtSourcePisPasep'))
const EFDContributionsTaxCreditCofins: any = lazyWithRetry((): any => import('pages/tax-efd-contributions/content/details/bookkeeping-complements/tax-credit-cofins/EFDContributionsTaxCreditCofins'))
const EFDContributionsAmountWithheldAtSourceCofins: any = lazyWithRetry((): any => import('pages/tax-efd-contributions/content/details/bookkeeping-complements/amounts-withheld-at-source-cofins/EFDContributionsAmountWithheldAtSourceCofins'))
const EFDContributionsRealEstateDevelopment: any = lazyWithRetry((): any => import('pages/tax-efd-contributions/content/details/bookkeeping-complements/real-estate-development/EFDContributionsRealEstateDevelopment'))
const EFDContributionsCashOrAccrualRegime: any = lazyWithRetry((): any => import('pages/tax-efd-contributions/content/details/bookkeeping-complements/cash-or-accrual-regimes/EFDContributionsCashOrAccrualRegime'))
const Ascertainments: any = lazyWithRetry((): any => import('pages/ascertainment/Ascertainments'))
const AscertainmentSubsidiaryTable: any = lazyWithRetry((): any => import('pages/ascertainment/content/AscertainmentSubsidiaryTable'))
const AscertainmentTaxes: any = lazyWithRetry((): any => import('pages/ascertainment/content/details/taxes/AscertainmentTaxPeriods'))
const TaxDetail: any = lazyWithRetry((): any => import('pages/ascertainment/content/details/taxes/tax/TaxDetail'))
const CalculationDetail: any = lazyWithRetry((): any => import('pages/ascertainment/content/details/invoice/CalculationDetail'))
const AccountingECDDetailed: any = lazyWithRetry((): any => import('pages/accounting-ecd/AccountingECD'))
const AccountingsECD: any = lazyWithRetry((): any => import('pages/accounting-ecd/AccountingsECD'))
const ECDOthersInformations: any = lazyWithRetry((): any => import('pages/accounting-ecd/content/details/accounting-statements/others-informations/ECDOthersInformations'))
const ECDSignatory: any = lazyWithRetry((): any => import('pages/accounting-ecd/content/details/signatory/ECDSignatory'))
const ECDEconomicConglomerate: any = lazyWithRetry((): any => import('pages/accounting-ecd/content/details/economic-conglomerate/ECDEconomicConglomerate'))
const ECDParticipant: any = lazyWithRetry((): any => import('pages/accounting-ecd/content/details/participant/ECDParticipant'))
const ECDAccountsPlan: any = lazyWithRetry((): any => import('pages/accounting-ecd/content/details/accounts-plan/ECDAccountsPlan'))
const ECDConsolidatedAccountsPlan: any = lazyWithRetry((): any => import('pages/accounting-ecd/content/details/economic-conglomerate/consolidated-accounts-plan/ECDConsolidatedAccountsPlan'))
const ECDConsolidatedAccountBalances: any = lazyWithRetry((): any => import('pages/accounting-ecd/content/details/economic-conglomerate/consolidated-account-balances/ECDConsolidatedAccountBalances'))
const ECDCompaniesHoldingTheParcels: any = lazyWithRetry((): any => import('pages/accounting-ecd/content/details/economic-conglomerate/consolidated-account-balances/companies-holding-the-parcels/ECDCompaniesHoldingTheParcels'))
const ECDConsolidatedCompaniesRelations: any = lazyWithRetry((): any => import('pages/accounting-ecd/content/details/economic-conglomerate/consolidated-companies-relations/ECDConsolidatedCompaniesRelations'))
const ECDCorporateEventsRelations: any = lazyWithRetry((): any => import('pages/accounting-ecd/content/details/economic-conglomerate/consolidated-companies-relations/corporate-events-relations/ECDCorporateEventsRelations'))
const ECDDlpaDmpl: any = lazyWithRetry((): any => import('pages/accounting-ecd/content/details/accounting-statements/dlpa-dmpl/ECDDlpaDmpl'))
const ECDDlpaDmplStatement: any = lazyWithRetry((): any => import('pages/accounting-ecd/content/details/accounting-statements/dlpa-dmpl/dlpa-dmpl-statement/ECDDlpaDmplStatement'))
const ECDBalanceSheet: any = lazyWithRetry((): any => import('pages/accounting-ecd/content/details/accounting-statements/balance-sheet/ECDBalanceSheet'))
const ECDBalanceSheetStatement: any = lazyWithRetry((): any => import('pages/accounting-ecd/content/details/accounting-statements/balance-sheet/balance-sheet-statement/ECDBalanceSheetStatement'))
const ECDDre: any = lazyWithRetry((): any => import('pages/accounting-ecd/content/details/accounting-statements/dre/ECDDre'))
const ECDDreStatement: any = lazyWithRetry((): any => import('pages/accounting-ecd/content/details/accounting-statements/dre/dre-statement/ECDDreStatement'))
const AccountingECFDetailed: any = lazyWithRetry((): any => import('pages/accounting-ecf/AccountingECF'))
const AccountingsECF: any = lazyWithRetry((): any => import('pages/accounting-ecf/AccountingsECF'))
const IdentificationOfMembersOrHolders: any = lazyWithRetry((): any => import('pages/accounting-ecf/content/details/general-information/IdentificationOfMembersOrHolders'))
const ECFAscertainmentDetail: any = lazyWithRetry((): any => import('pages/accounting-ecf/content/details/ascertainment/AscertainmentDetail'))
const AccountIdentification: any = lazyWithRetry((): any => import('pages/accounting-ecf/content/details/ascertainment/real/lalur-lacs-b/AccountIdentificationDetail'))
const LaunchesWithoutReflections: any = lazyWithRetry((): any => import('pages/accounting-ecf/content/details/ascertainment/real/lalur-lacs-b/LaunchesWithoutReflections'))
const WebHookDeliveries: any = lazyWithRetry((): any => import('pages/webhook-deliveries/WebHookDeliveries'))
const NotFound: any = lazyWithRetry((): any => import('pages/not-found/NotFound'))
const ComingSoon: any = lazyWithRetry((): any => import('pages/coming-soon/ComingSoon'))
const Webhook: any = lazyWithRetry((): any => import('pages/webhooks/Webhook'))
const Certificate: any = lazyWithRetry((): any => import('pages/certificate/content/CertificateDetail'))
const Certificates: any = lazyWithRetry((): any => import('pages/certificate/Certificates'))
const Mailbox: any = lazyWithRetry((): any => import('pages/mailbox/content/MailboxDetail'))
const Mailboxs: any = lazyWithRetry((): any => import('pages/mailbox/Mailboxs'))
const Balances: any = lazyWithRetry((): any => import('pages/balance/Balances'))
const AccountBalance: any = lazyWithRetry((): any => import('pages/balance/AccountBalanceIdentification'))
const Balance: any = lazyWithRetry((): any => import('pages/balance/Balance'))
const EntriesItemsBalance: any = lazyWithRetry((): any => import('pages/accounting-entries/EntriesItemsBalance'))
const EntriesItems: any = lazyWithRetry((): any => import('pages/accounting-entries/EntriesItems'))
const BalancesSheets: any = lazyWithRetry((): any => import('pages/balances-sheets-companies/BalancesSheetsCompanies'))
const BalanceSheets: any = lazyWithRetry((): any => import('pages/balances-sheets-companies/BalancesSheetsCompaniesDetail'))
const BalanceSheet: any = lazyWithRetry((): any => import('pages/balance-sheet/BalanceSheet'))
const IncomeStatement: any = lazyWithRetry((): any => import('pages/income-statement/IncomeStatement'))
const AccountIncomeStatementIdentification: any = lazyWithRetry((): any => import('pages/income-statement/AccountIncomeStatementIdentification'))
const IncomeStatements: any = lazyWithRetry((): any => import('pages/income-statement/IncomeStatements'))
const ComprehensiveResults: any = lazyWithRetry((): any => import('pages/comprehensive-result/ComprehensiveResults'))
const ComprehensiveResult: any = lazyWithRetry((): any => import('pages/comprehensive-result/ComprehensiveResult'))
const AccountComprehensiveResultIdentification: any = lazyWithRetry((): any => import('pages/comprehensive-result/AccountComprehensiveResultIdentification'))
const AddedValues: any = lazyWithRetry((): any => import('pages/added-value/AddedValues'))
const AddedValue: any = lazyWithRetry((): any => import('pages/added-value/AddedValue'))
const AccountAddedValueIdentification: any = lazyWithRetry((): any => import('pages/added-value/AccountAddedValueIdentification'))
const CashFlows: any = lazyWithRetry((): any => import('pages/cash-flow/CashFlows'))
const CashFlow: any = lazyWithRetry((): any => import('pages/cash-flow/CashFlow'))
const AccountCashFlowIdentification: any = lazyWithRetry((): any => import('pages/cash-flow/AccountCashFlowIdentification'))
const Ledgers: any = lazyWithRetry((): any => import('pages/ledger/Ledgers'))
const Ledger: any = lazyWithRetry((): any => import('pages/ledger/Ledger'))
const LedgerAccounts: any = lazyWithRetry((): any => import('pages/ledger/content/Ledger.Accounts'))
const HeritageMutations: any = lazyWithRetry((): any => import('pages/heritage-mutation/HeritageMutations'))
const HeritageMutation: any = lazyWithRetry((): any => import('pages/heritage-mutation/HeritageMutation'))
const AccountHeritageMutationIdentification: any = lazyWithRetry((): any => import('pages/heritage-mutation/AccountHeritageMutationIdentification'))
const TaxMenu: any = lazyWithRetry((): any => import('pages/tax-menu/TaxMenu'))

const prodEnviroment = process.env.REACT_APP_CONE_API_ENVIRONMENT === 'production'

const routesList: Array<IRoute> = [
  {
    component: prodEnviroment ? ComingSoon : Dashboard,
    path: routePaths.DASHBOARD,
    exact: false
  },
  {
    component: Settings,
    path: routePaths.SETTINGS,
    exact: false
  },
  {
    component: Users,
    path: routePaths.USERS,
    exact: false
  },
  {
    component: User,
    path: routePaths.USER,
    exact: false
  },
  {
    component: Billing,
    path: routePaths.BILLING,
    exact: false
  },
  {
    component: Whitelabels,
    path: routePaths.WHITELABELS,
    exact: false
  },
  {
    component: Whitelabel,
    path: routePaths.WHITELABEL,
    exact: false
  },
  {
    component: Accounts,
    path: routePaths.ACCOUNTS,
    exact: false
  },
  {
    component: Account,
    path: routePaths.ACCOUNT,
    exact: false
  },
  {
    component: Diaries,
    path: routePaths.DIARIES,
    exact: false
  },
  {
    component: BalanceSheets,
    path: routePaths.BALANCE_SHEETS,
    exact: false
  },
  {
    component: BalancesSheets,
    path: routePaths.BALANCES_SHEETS,
    exact: false
  },
  {
    component: BalanceSheet,
    path: routePaths.BALANCE_SHEET,
    exact: false
  },
  {
    component: Balances,
    path: routePaths.BALANCES,
    exact: false
  },
  {
    component: Balance,
    path: routePaths.BALANCE,
    exact: false
  },
  {
    component: AccountBalance,
    path: routePaths.ACCOUNT_BALANCE,
    exact: false
  },
  {
    component: IncomeStatements,
    path: routePaths.INCOME_STATEMENTS,
    exact: false
  },
  {
    component: IncomeStatement,
    path: routePaths.INCOME_STATEMENT,
    exact: false
  },
  {
    component: AccountIncomeStatementIdentification,
    path: routePaths.ACCOUNT_INCOME_STATEMENTS,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : ComprehensiveResults,
    path: routePaths.COMPREHENSIVE_RESULTS,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : ComprehensiveResult,
    path: routePaths.COMPREHENSIVE_RESULT,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : AccountComprehensiveResultIdentification,
    path: routePaths.ACCOUNT_COMPREHENSIVE_RESULTS,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : AddedValues,
    path: routePaths.ADDED_VALUES,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : AddedValue,
    path: routePaths.ADDED_VALUE,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : AccountAddedValueIdentification,
    path: routePaths.ACCOUNT_ADDED_VALUES,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : CashFlows,
    path: routePaths.CASH_FLOWS,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : CashFlow,
    path: routePaths.CASH_FLOW,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : AccountCashFlowIdentification,
    path: routePaths.ACCOUNT_CASH_FLOWS,
    exact: false
  },
  {
    component: Ledgers,
    path: routePaths.LEDGERS,
    exact: false
  },
  {
    component: Ledger,
    path: routePaths.LEDGER,
    exact: false
  },
  {
    component: LedgerAccounts,
    path: routePaths.LEDGER_ACCOUNTS,
    exact: false
  },
  {
    component: HeritageMutations,
    path: routePaths.HERITAGE_MUTATIONS,
    exact: false
  },
  {
    component: HeritageMutation,
    path: routePaths.HERITAGE_MUTATION,
    exact: false
  },
  {
    component: AccountHeritageMutationIdentification,
    path: routePaths.ACCOUNT_HERITAGE_MUTATIONS,
    exact: false
  },
  {
    component: EntriesItems,
    path: routePaths.ACCOUNTING_ENTRIES_DIARY,
    exact: false
  },
  {
    component: EntriesItemsBalance,
    path: routePaths.ACCOUNTING_ENTRIES_BALANCE,
    exact: false
  },
  {
    component: Diary,
    path: routePaths.DIARY,
    exact: false
  },
  {
    component: Companies,
    path: routePaths.COMPANIES,
    exact: false
  },
  {
    component: Company,
    path: routePaths.COMPANY,
    exact: false
  },
  {
    component: Subsidiaries,
    path: routePaths.SUBSIDIARIES,
    exact: false
  },
  {
    component: Subsidiary,
    path: routePaths.SUBSIDIARY,
    exact: false
  },
  {
    component: Acquirer,
    path: routePaths.ACQUIRER,
    exact: false
  },
  {
    component: Device,
    path: routePaths.DEVICE,
    exact: false
  },
  {
    component: Configurations,
    path: routePaths.CONFIGURATION,
    exact: false
  },
  {
    component: Department,
    path: routePaths.DEPARTMENT,
    exact: false
  },
  {
    component: Category,
    path: routePaths.CATEGORY,
    exact: false
  },
  {
    component: Product,
    path: routePaths.PRODUCT,
    exact: false
  },
  {
    component: RulesEngines,
    path: routePaths.RULES_ENGINES,
    exact: false
  },
  {
    component: RulesEngine,
    path: routePaths.RULES_ENGINE,
    exact: false
  },
  {
    component: Invoices,
    path: routePaths.INVOICES,
    exact: false
  },
  {
    component: Invoice,
    path: routePaths.INVOICE,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : Financial,
    path: routePaths.FINANCIAL,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : Transactions,
    path: routePaths.TRANSACTIONS,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : Transaction,
    path: routePaths.TRANSACTION,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : Duplicate,
    path: routePaths.TRANSACTION_DUPLICATE_DETAIL,
    exact: false
  },
  {
    component: Serie,
    path: routePaths.SERIE,
    exact: false
  },
  {
    component: Participant,
    path: routePaths.PARTICIPANT,
    exact: false
  },
  {
    component: Terminal,
    path: routePaths.TERMINAL,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : Ascertainments,
    path: routePaths.ASCERTAINMENTS,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : AscertainmentSubsidiaryTable,
    path: routePaths.ASCERTAINMENT_SUBSIDIARY,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : AscertainmentTaxes,
    path: routePaths.ASCERTAINMENT_TAXES,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : TaxDetail,
    path: routePaths.TAX,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : CalculationDetail,
    path: routePaths.CALCULATION_DETAIL,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : TaxsEfdIcmsIpi,
    path: routePaths.TAXS_EFD_ICMS_IPI,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : TaxEFDContributions,
    path: routePaths.TAX_EFD_CONTRIBUTIONS,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : TaxsEFDContributions,
    path: routePaths.TAXS_EFD_CONTRIBUTIONS,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : EFDContributionsSignatory,
    path: routePaths.EFD_CONTRIBUTIONS_SIGNATORY,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : EFDContributionsAccountsPlan,
    path: routePaths.EFD_CONTRIBUTIONS_ACCOUNTS_PLAN,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : EFDContributionsSubsidiary,
    path: routePaths.EFD_CONTRIBUTIONS_SUBSIDIARIES,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : EFDContributionsRegime,
    path: routePaths.EFD_CONTRIBUTIONS_REGIME,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : EFDContributionsParticipant,
    path: routePaths.EFD_CONTRIBUTIONS_PARTICIPANT,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : EFDContributionsItem,
    path: routePaths.EFD_CONTRIBUTIONS_ITEM,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : EFDContributionsOtherDocuments,
    path: routePaths.EFD_CONTRIBUTIONS_OTHER_DOCUMENTS,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : EFDContributionsContributionAndCredit,
    path: routePaths.EFD_CONTRIBUTIONS_CONTRIBUTION_AND_CREDIT,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : EFDContributionsDepreciationCharge,
    path: routePaths.EFD_CONTRIBUTIONS_DEPRECIATION_CHARGE,
    exact: false,
  },
  {
    component: prodEnviroment ? ComingSoon : AscertainmentTax,
    path: routePaths.ASCERTAINMENT_TAX,
    exact: false,
  },
  {
    component: prodEnviroment ? ComingSoon : EFDContributionsAcquisitionValue,
    path: routePaths.EFD_CONTRIBUTIONS_ACQUISITION_VALUE,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : EFDContributionsPresumedCredit,
    path: routePaths.EFD_CONTRIBUTIONS_PRESUMED_CREDIT,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : EFDContributionsSoldRealEstateUnit,
    path: routePaths.EFD_CONTRIBUTIONS_SOLD_REAL_ESTATE_UNIT,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : SoldRealEstateUnitIncurredCost,
    path: routePaths.SOLD_REAL_ESTATE_UNIT_INCURRED_COST,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : SoldRealEstateUnitBudgetedCost,
    path: routePaths.SOLD_REAL_ESTATE_UNIT_BUDGETED_COST,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : EFDContributionsCashRegime,
    path: routePaths.EFD_CONTRIBUTIONS_CASH_REGIME,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : EFDContributionsOperationWithIncidence,
    path: routePaths.EFD_CONTRIBUTIONS_OPERATION_WITH_INCIDENCE,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : EFDContributionsRevenueComposition,
    path: routePaths.EFD_CONTRIBUTIONS_REVENUE_COMPOSITION,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : EFDContributionsWithholdingContribution,
    path: routePaths.EFD_CONTRIBUTIONS_WITHHOLDING_CONTRIBUTION,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : EFDContributionsVariousDeduction,
    path: routePaths.EFD_CONTRIBUTIONS_VARIOUS_DEDUCTION,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : EFDContributionsResultingCredit,
    path: routePaths.EFD_CONTRIBUTIONS_RESULTING_CREDIT,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : EFDContributionsAscertainment,
    path: routePaths.EFD_CONTRIBUTIONS_ASCERTAINMENT,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : PisPasepPeriodCredit,
    path: routePaths.PIS_PASEP_PERIOD_CREDIT,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : PisPasepCalculationBase,
    path: routePaths.PIS_PASEP_CALCULATION_BASE,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : PisPasepCreditAdjustment,
    path: routePaths.PIS_PASEP_CREDIT_ADJUSTMENT,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : PisPasepDetailedCreditAdjustment,
    path: routePaths.PIS_PASEP_DETAILED_CREDIT_ADJUSTMENT,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : PisPasepContributionPeriod,
    path: routePaths.PIS_PASEP_CONTRIBUTION_PERIOD,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : PisPasepRevenueCode,
    path: routePaths.PIS_PASEP_REVENUE_CODE,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : PisPasepContributionCode,
    path: routePaths.PIS_PASEP_CONTRIBUTION_CODE,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : PisPasepCooperativeSociety,
    path: routePaths.PIS_PASEP_CONTRIBUTION_COOPERATIVE_SOCIETY,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : PisPasepCalculationBaseAdjustment,
    path: routePaths.PIS_PASEP_CALCULATION_BASE_ADJUSTMENT,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : PisPasepContributionAdjustment,
    path: routePaths.PIS_PASEP_CONTRIBUTION_ADJUSTMENT,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : PisPasepAdditionalInformation,
    path: routePaths.PIS_PASEP_ADDITIONAL_INFORMATION,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : PisPasepFitDetailed,
    path: routePaths.PIS_PASEP_FIT_DETAILED,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : PisPasepContributionsFromPreviousPeriod,
    path: routePaths.PIS_PASEP_CONTRIBUTIONS_FROM_PREVIOUS_PERIOD,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : PisPasepPayroll,
    path: routePaths.PIS_PASEP_PAYROLL,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : PisPasepExemptRecipe,
    path: routePaths.PIS_PASEP_EXEMPT_RECIPE,
    exact: false
  },

  {
    component: prodEnviroment ? ComingSoon : CofinsPeriodCredit,
    path: routePaths.COFINS_PERIOD_CREDIT,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : CofinsCalculationBase,
    path: routePaths.COFINS_CALCULATION_BASE,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : CofinsCreditAdjustment,
    path: routePaths.COFINS_CREDIT_ADJUSTMENT,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : CofinsDetailedCreditAdjustment,
    path: routePaths.COFINS_DETAILED_CREDIT_ADJUSTMENT,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : CofinsContributionPeriod,
    path: routePaths.COFINS_CONTRIBUTION_PERIOD,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : CofinsRevenueCode,
    path: routePaths.COFINS_REVENUE_CODE,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : CofinsContributionCode,
    path: routePaths.COFINS_CONTRIBUTION_CODE,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : CofinsCooperativeSociety,
    path: routePaths.COFINS_COOPERATIVE_SOCIETY,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : CofinsCalculationBaseAdjustment,
    path: routePaths.COFINS_CALCULATION_BASE_ADJUSTMENT,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : CofinsContributionAdjustment,
    path: routePaths.COFINS_CONTRIBUTION_ADJUSTMENT,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : CofinsAdditionalInformation,
    path: routePaths.COFINS_ADDITIONAL_INFORMATION,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : CofinsFitDetailed,
    path: routePaths.COFINS_FIT_DETAILED,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : CofinsAmountsPayableInThePeriod,
    path: routePaths.COFINS_AMOUNTS_PAYABLE_IN_THE_PERIOD,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : CofinsExemptRecipe,
    path: routePaths.COFINS_EXEMPT_RECIPE,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : CprbRevenueBookkeeping,
    path: routePaths.CPRB_REVENUE_BOOKKEEPING,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : CprbAboutTheValue,
    path: routePaths.CPRB_ABOUT_THE_VALUE,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : Consolidation,
    path: routePaths.CPRB_CONSOLIDATION,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : ContributionAdjustment,
    path: routePaths.CPRB_CONTRIBUTION_ADJUSTMENT,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : BookkeepingSupplement,
    path: routePaths.CPRB_BOOKKEEPING_SUPPLEMENT,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : EFDContributionsBookkeepingComplements,
    path: routePaths.EFD_CONTRIBUTIONS_BOOKKEEPING_COMPLEMENTS,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : EFDContributionsLawsuit,
    path: routePaths.BOOKKEEPING_COMPLEMENTS_LAWSUIT,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : LawsuitRequiredContribution,
    path: routePaths.LAWSUIT_REQUIRED_CONTRIBUTION,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : EFDContributionsExtraCalculationValue,
    path: routePaths.BOOKKEEPING_COMPLEMENTS_CALCULATION_VALUE,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : EFDContributionsTaxCreditPisPasep,
    path: routePaths.BOOKKEEPING_COMPLEMENTS_TAX_CREDIT_PIS_PASEP,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : EFDContributionsAmountWithheldAtSourcePisPasep,
    path: routePaths.BOOKKEEPING_COMPLEMENTS_AMOUNT_WITHHELD_AT_SOURCE_PIS_PASEP,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : EFDContributionsTaxCreditCofins,
    path: routePaths.BOOKKEEPING_COMPLEMENTS_TAX_CREDIT_COFINS,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : EFDContributionsAmountWithheldAtSourceCofins,
    path: routePaths.BOOKKEEPING_COMPLEMENTS_AMOUNT_WITHHELD_AT_SOURCE_COFINS,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : EFDContributionsRealEstateDevelopment,
    path: routePaths.BOOKKEEPING_COMPLEMENTS_REAL_ESTATE_DEVELOPMENT,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : EFDContributionsCashOrAccrualRegime,
    path: routePaths.BOOKKEEPING_COMPLEMENTS_CASH_OR_ACCRUAL_REGIME,
    exact: false
  },
  //
  {
    component: prodEnviroment ? ComingSoon : TaxEfdIcmsIpi,
    path: routePaths.TAX_EFD_ICMS_IPI,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : AccountingECDDetailed,
    path: routePaths.ACCOUNTING_ECD,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : AccountingsECD,
    path: routePaths.ACCOUNTINGS_ECD,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : ECDOthersInformations,
    path: routePaths.ECD_OTHERS_INFORMATIONS,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : ECDSignatory,
    path: routePaths.ECD_SIGNATORY,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : ECDEconomicConglomerate,
    path: routePaths.ECD_ECONOMIC_CONGLOMERATE,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : ECDParticipant,
    path: routePaths.ECD_PARTICIPANT,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : ECDAccountsPlan,
    path: routePaths.ECD_ACCOUNTS_PLAN,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : ECDConsolidatedAccountsPlan,
    path: routePaths.ECD_CONSOLIDATED_ACCOUNTS_PLAN,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : ECDConsolidatedAccountsPlan,
    path: routePaths.ECD_CONSOLIDATED_ACCOUNTS_PLAN,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : ECDConsolidatedAccountBalances,
    path: routePaths.ECD_CONSOLIDATED_ACCOUNT_BALANCES,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : ECDCompaniesHoldingTheParcels,
    path: routePaths.ECD_COMPANIES_HOLDING_THE_PARCELS,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : ECDConsolidatedCompaniesRelations,
    path: routePaths.ECD_CONSOLIDATED_COMPANIES_RELATIONS,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : ECDCorporateEventsRelations,
    path: routePaths.ECD_CORPORATE_EVENTS_RELATIONS,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : ECDDlpaDmpl,
    path: routePaths.ECD_DLPA_DMPL,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : ECDDlpaDmplStatement,
    path: routePaths.ECD_DLPA_DMPL_STATEMENT,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : ECDBalanceSheet,
    path: routePaths.ECD_BALANCE_SHEET,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : ECDBalanceSheetStatement,
    path: routePaths.ECD_BALANCE_SHEET_STATEMENT,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : ECDDre,
    path: routePaths.ECD_DRE,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : ECDDreStatement,
    path: routePaths.ECD_DRE_STATEMENT,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : AccountingECFDetailed,
    path: routePaths.ACCOUNTING_ECF,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : AccountingsECF,
    path: routePaths.ACCOUNTINGS_ECF,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : IdentificationOfMembersOrHolders,
    path: routePaths.IDENTIFICATION_OF_MEMBERS_OR_HOLDERS,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : ECFAscertainmentDetail,
    path: routePaths.ECF_ASCERTAINMENT_DETAIL,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : AccountIdentification,
    path: routePaths.ACCOUNT_IDENTIFICATION,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : LaunchesWithoutReflections,
    path: routePaths.LAUNCHES_WITHOUT_REFLECTIONS,
    exact: false
  },
  {
    component: AccountPlan,
    path: routePaths.ACCOUNT_PLAN,
    exact: false
  },
  {
    component: AccountPlanAccountsContent,
    path: routePaths.ACCOUNT_PLAN_ACCOUNT_DETAILED,
    exact: false
  },
  {
    component: Signatory,
    path: routePaths.SIGNATORY,
    exact: false
  },
  {
    component: Webhook,
    path: routePaths.WEBHOOK,
    exact: false
  },
  {
    component: WebHookDeliveries,
    path: routePaths.WEBHOOKS,
    exact: false
  },
  {
    component: WebHookDeliveries,
    path: routePaths.WEBHOOK_DETAILED,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : Certificate,
    path: routePaths.CERTIFICATE,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : Certificates,
    path: routePaths.CERTIFICATES,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : Mailbox,
    path: routePaths.MAILBOX,
    exact: false
  },
  {
    component: prodEnviroment ? ComingSoon : Mailboxs,
    path: routePaths.MAILBOXS,
    exact: false
  },
  {
    component: TaxMenu,
    path: routePaths.TAX_MENU,
    exact: false
  },
  {
    component: NotFound,
    path: routePaths.NOT_FOUND,
    exact: false
  }
]

export default routesList
